import { Box, CircularProgress } from "@mui/material";
import { colors } from '../theme';

const CenteredCircularProgress = () => {

    return (
        <Box sx={{ display: 'flex', width: "100%", justifyContent: 'center' }}>
            <CircularProgress sx={{ color: colors.pink }} />
        </Box>
    )
}

export default CenteredCircularProgress;