import React from 'react';
import ReactDOM from 'react-dom/client';
import './normalise.scss';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

import App from './App';

import { AuthProvider } from "contexts/AuthContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<AuthProvider>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</AuthProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
