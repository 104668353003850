import { Box, useTheme } from "@mui/material";
import BlurryCircle from "components/BlurryCircle";
import NoiseSvg from "components/NoiseSvg";
import { colors } from "theme";

const HazyBackground = ({children}) => {

    const theme = useTheme();

    return (
        <Box
            sx={{
                minHeight: "100vh",
                // margin: "0 10em",
                // backgroundColor: "white",
                // fontFamily: "Recoleta, sans-serif",
                position: "relative",
                overflow: 'hidden',
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                "::before": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: -1,
                    opacity: 0.7,
                    background: theme.palette.primary.main,
                    filter: "url(#noiseFilter)",
                },
            }}
        >

            <Box
                sx={{
                    zIndex: 1,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                {children}
            </Box>

            <NoiseSvg />
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
            >
                <BlurryCircle color={colors.pink} posX={"11%"} posY={"15%"} />
                <BlurryCircle color={colors.white} posX={"13%"} posY={"63%"} />
                <BlurryCircle color={colors.white} posX={"41%"} posY={"-15%"} />
                <BlurryCircle color={colors.pink} posX={"81%"} posY={"47%"} />
            </Box>
        </Box >
    )
}

export default HazyBackground;