import { Box, Card, FormControl, FormLabel, Stack, TextField, Typography, Link, FormControlLabel, Checkbox, Button, Divider, DialogContent, DialogContentText, DialogActions, DialogTitle, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { doSignInWithEmailAndPassword, doSignInWithGoogle } from "remote/auth";
import googleLogo from 'assets/Login/GoogleLogo.png'
import HazyBackground from "components/HazyBackground";

const SignIn = () => {
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    // const [open, setOpen] = useState(false);
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { userLoggedIn } = useAuth();

    useEffect(() => {
        if (userLoggedIn) {
            navigate("/");
        }

        // Check if the logout reason is "device-conflict"
        if (searchParams.get("lr") === "device-conflict") {
            setDialogOpen(true);
        }
    }, [navigate, userLoggedIn, searchParams]);


    const handleSubmit = (event) => {
        event.preventDefault();

        if (isSigningIn)
            return
        setIsSigningIn(true);

        if (emailError || passwordError) {
            setIsSigningIn(false);
            return;
        }

        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = data.get('password');

        doSignInWithEmailAndPassword(email, password)
            .catch((error) => {
                console.log(error);
                setPasswordErrorMessage(String(error));
            })
            .then((userCredential) => {
                console.log(userCredential);
                navigate('/');
            })
            .finally(() => {
                setIsSigningIn(false);
            });
    };

    const onGoogleSignIn = (e) => {
        e.preventDefault()
        if (isSigningIn)
            return

        setIsSigningIn(true)
        doSignInWithGoogle()
            .catch((error) => {
                console.log(error);
                setPasswordErrorMessage(String(error));
            })
            .then((userCredential) => {
                console.log(userCredential);
                navigate('/');
            })
            .finally(() => {
                setIsSigningIn(false);
            });
    }

    const validateInputs = () => {
        const email = document.getElementById('email');
        const password = document.getElementById('password');

        let isValid = true;

        if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
            setEmailError(true);
            setEmailErrorMessage('Please enter a valid email address.');
            isValid = false;
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
        }

        if (!password.value || password.value.length < 6) {
            setPasswordError(true);
            setPasswordErrorMessage('Password must be at least 6 characters long.');
            isValid = false;
        } else {
            setPasswordError(false);
            setPasswordErrorMessage('');
        }

        return isValid;
    };

    return (
        <HazyBackground>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Session Conflict</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You have been logged out because your account was accessed on another device.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Card
                sx={{
                    maxWidth: '512px',
                    width: '80%',
                    padding: 4,
                    borderRadius: 8,
                    zIndex: 1,
                }}
            >
                <Stack spacing={2}>
                    <Typography variant="h3">Sign In</Typography>

                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                        }}
                    >
                        <FormControl>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <TextField
                                error={emailError}
                                helperText={emailErrorMessage}
                                id="email"
                                type="email"
                                name="email"
                                placeholder="your@email.com"
                                autoComplete="email"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={emailError ? 'error' : 'primary'}
                                sx={{ ariaLabel: 'email' }}
                            />
                        </FormControl>
                        <FormControl>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormLabel htmlFor="password">Password</FormLabel>
                                <Link
                                    component="button"
                                    type="button"
                                    // onClick={handleClickOpen}
                                    variant="body2"
                                    sx={{ alignSelf: 'baseline' }}
                                >
                                    Forgot your password?
                                </Link>
                            </Box>
                            <TextField
                                error={passwordError}
                                helperText={passwordErrorMessage}
                                name="password"
                                placeholder="••••••"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={passwordError ? 'error' : 'primary'}
                            />
                        </FormControl>
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        {/* <ForgotPassword open={open} handleClose={handleClose} /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={validateInputs}
                        >
                            Sign in
                        </Button>
                        <Typography sx={{ textAlign: 'center' }}>
                            Don't have an account?{' '}
                            <span>
                                <Link
                                    href="/sign-up/"
                                    variant="body2"
                                    sx={{ alignSelf: 'center' }}
                                >
                                    Sign up
                                </Link>
                            </span>
                        </Typography>
                    </Box>
                    <Divider><Typography variant="body2">or</Typography></Divider>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={onGoogleSignIn}
                            startIcon={<img src={googleLogo} alt="google logo" height={32} />}
                        >
                            <Typography variant="body2">Sign in with Google</Typography>
                        </Button>
                    </Box>
                </Stack>
            </Card>

        </HazyBackground >
    )
}

export default SignIn;