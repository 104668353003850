import { Box, Paper, Stack, Typography, Avatar, Button, Tooltip, Grid2 as Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CenteredCircularProgress from 'components/CenteredCircularProgress';
import { httpsCallableFromURL } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { functions } from 'remote/firebase';
import LanguageSelectDialog from './components/LanguageSelect';
import FileProcessingStatusBox from './components/fileProcessingStatusBox';
import CompaniesTable from './components/companiesTable';
// import CompaniesTable from './components/companiesTable';

const DiscoveredCompanies = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]); // State to track selected rows
    const [deleteCompanySuccess, setDeleteCompanySuccess] = useState(true);
    const [deleteCompanyResponseMessage, setDeleteCompanyResponseMessage] = useState("");

    function replaceUuidWithId(companies) {
        companies.forEach(company => {
            company.id = company.uuid;
            delete company.uuid;
        });
        return companies;
    }

    const [language, setLanguage] = useState('All');

    useEffect(() => { setSelectedRows([]) }, [loading]);

    async function getDiscoveredCompanies() {
        setLoading(true);

        let getDiscoveredCompanies;
        if (language !== "All") {
            getDiscoveredCompanies = httpsCallableFromURL(
                functions,
                "https://europe-west1-two-degrees-search.cloudfunctions.net/getCompaniesByLanguage"
            );
        } else {
            getDiscoveredCompanies = httpsCallableFromURL(
                functions,
                "https://europe-west1-two-degrees-search.cloudfunctions.net/getAllDiscoveredCompanies"
            );
        }
        getDiscoveredCompanies({ programming_language: language })
            .then((result) => {
                const data = result.data;
                console.log(data);

                const preparedData = replaceUuidWithId(data.companies);
                setRows(preparedData);
            })
            .catch((error) => {
                console.error("Error fetching companies: ", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getDiscoveredCompanies();
    }, [language]);

    function renderAvatar(params) {
        if (params.value == null) {
            return '';
        }

        return (
            <Avatar alt={params.value.name} src={params.value.logoUrl} sx={{ backgroundColor: params.value.logoUrl ? "" : stringToColor(params.value.name) }}>
                {params.value.name.toUpperCase().substring(0, 1)}
            </Avatar>
        );
    }

    function renderLink(params) {
        return params.value.url ?
            <Link to={params.value.url}>{params.value.text}</Link> : null;
    }

    function stringToColor(string) {
        let hash = 0;
        for (let i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';
        for (let i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        return color;
    }

    const columns = [
        {
            field: 'logo',
            headerName: 'Logo',
            renderCell: renderAvatar,
            valueGetter: (value, row) => {
                return row.name == null && row.company_logo_url == null ?
                    null : { name: `${row.name || ''}`, logoUrl: row.company_logo_url };
            },
            sortable: false,
            filterable: false,
            groupable: false,
            aggregable: false,
            disableExport: true,
        },
        {
            field: 'name',
            headerName: 'Company Name',
            width: 200
        },
        {
            field: 'employee_count',
            headerName: 'Number of Staff',
            type: 'number',
            width: 150,
        },
        {
            field: 'linkedin_url',
            headerName: 'LinkedIn URL',
            width: 250,
            renderCell: renderLink,
            valueGetter: (value, row) => {
                return { url: `https://linkedin.com/company/${row.li_id}/`, text: 'Linked In' };
            }
        },
        {
            field: 'websiteUrl',
            headerName: 'Website URL',
            width: 250,
            renderCell: renderLink,
            valueGetter: (value, row) => {
                return { url: row.website_url, text: 'Website' };
            }
        },
    ];

    const paginationModel = { page: 0, pageSize: 25 };

    const handleExportToCSV = () => {
        const csvContent = [
            ["ID", "Company Name", "Number of Staff", "LinkedIn URL", "Website URL"],
            ...selectedRows.map(row => [
                row.id,
                row.name,
                row.employee_count,
                `https://linkedin.com/company/${row.li_id}/`,
                row.website_url
            ])
        ]
            .map(e => e.join(","))
            .join("\n");

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "selected_companies.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    function deleteSelectedRows() {
        const selectedIds = selectedRows.map(row => row.id);
        console.log(selectedIds);

        // Validate the selected IDs
        if (selectedIds.length === 0) {
            console.error("No rows selected for deletion.");
            return;
        }

        // Create a callable function
        const deleteCompanies = httpsCallableFromURL(
            functions,
            "https://europe-west1-two-degrees-search.cloudfunctions.net/deleteCompanies"
        );

        // Call the function
        deleteCompanies({
            company_ids: selectedIds,
        })
            .then(response => {
                console.log("Successfully deleted companies:", response.data);
                setDeleteCompanySuccess(true);
                setDeleteCompanyResponseMessage("Delete successful");
                
                getDiscoveredCompanies();
            })
            .catch(error => {
                console.error("Error deleting companies:", error);
                setDeleteCompanySuccess(false);
                setDeleteCompanyResponseMessage(`Delete failed: ${error}`);
            });
    }

    return (
        <Stack
            spacing={2}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingX: 16,
                paddingY: 8,
            }}
        >
            <Typography variant="h2">Discovered Companies</Typography>
            <Link to={"/upload-cv"}>
                <Typography variant='subtitle1'>Upload new CVs/LinkedIn Urls</Typography>
            </Link>
            <Grid container width={'100%'}>
                <Grid size={5}>
                    <Stack spacing={2}>
                        <Typography variant='h4'>Filters</Typography>
                        <Box>
                            <Typography variant='h5'>Programming Language</Typography>
                            <LanguageSelectDialog
                                language={language}
                                setLanguage={setLanguage}
                            />
                        </Box>
                    </Stack>
                </Grid>
                <Grid size={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <FileProcessingStatusBox onComplete={() => { getDiscoveredCompanies() }} />
                </Grid>
            </Grid>

            <Typography variant="h4" sx={{ marginBottom: 2 }}>
                Companies you should contact
            </Typography>
            {
                loading ?
                    <CenteredCircularProgress /> :
                    <Paper sx={{ width: '100%' }}>
                        {/* <CompaniesTable /> */}
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSizeOptions={[5, 10, 25, 100]}
                            checkboxSelection
                            onRowSelectionModelChange={(ids) => {
                                const selected = rows.filter(row => ids.includes(row.id));
                                setSelectedRows(selected);
                            }}
                            initialState={{ pagination: { paginationModel } }}
                            sx={{ border: 0 }}
                        />
                    </Paper>
            }
            <Box
                direction={'row'}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '100%',
                }}
            >
                <Stack spacing={2}>
                    <Stack spacing={2} direction={'row'}>
                        <Tooltip title={selectedRows.length === 0 ? "You must first select rows to export" : ""} arrow sx={{ marginY: 2 }}>
                            <span>
                                <Button
                                    variant='outlined'
                                    disabled={selectedRows.length === 0}
                                    onClick={deleteSelectedRows}
                                >
                                    Delete selected
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title={selectedRows.length === 0 ? "You must first select rows to export" : ""} arrow sx={{ marginY: 2 }}>
                            <span>
                                <Button
                                    variant='contained'
                                    onClick={handleExportToCSV}
                                    disabled={selectedRows.length === 0}
                                >
                                    Export selected companies to CSV
                                </Button>
                            </span>
                        </Tooltip>
                    </Stack>
                    <Typography variant='body2' color={deleteCompanySuccess ? 'green' : 'red'} textAlign={'right'}>{deleteCompanyResponseMessage}</Typography>

                </Stack>

            </Box>
        </Stack >
    );
};

export default DiscoveredCompanies;
