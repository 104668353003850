import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import { Box, Stack, Typography } from '@mui/material';

import TwoDotLogoPink from 'assets/Two_Dot_pink.png';

import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { doSignOut } from 'remote/auth';
// import TwoDotLogoWhite from '../assets/Two_Dot_white.png';

const NavBar = () => {

    const navigate = useNavigate();
    const { currentUser } = useAuth();

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 10,
                    color: '#F5F4F455',
                    padding: '1rem 2rem',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box onClick={() => { navigate("/") }} sx={{cursor: 'pointer'}}>
                       <img src={TwoDotLogoPink} alt="two dot logo" height={64} style={{cursor: 'pointer'}}/>
                    </Box>
                    {
                        currentUser &&
                        <Stack direction={'row'} spacing={2}>

                            <Typography variant='body2' sx={{ cursor: 'default' }}>{currentUser.displayName}</Typography>
                            <Typography
                                variant='body2'
                                sx={{ cursor: 'pointer' }}
                                onClick={() => { doSignOut().then(() => navigate('/')) }}
                            >
                                Logout
                            </Typography>
                        </Stack>
                    }
                </Box>
            </Box>
        </ThemeProvider>
    )
};

export default NavBar