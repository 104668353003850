import { Card, Stack, Box, FormControl, FormLabel, Typography, Button } from "@mui/material"
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { uploadFileToFirebase } from "remote/firebaseStorage";
import CenteredCircularProgress from "components/CenteredCircularProgress";

const UploadCV = () => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadingFile, setUploadingFile] = useState(false);

    const navigate = useNavigate();

    // On file select (from the pop up)
    function onFileChange(event) {
        // Update the state
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async () => {
        if (!selectedFile) return;
        setUploadingFile(true);

        try {
            // Upload file to Firebase Storage
            const uploadMetadata = await uploadFileToFirebase(selectedFile);
            console.log("Uploaded file to Firebase Storage:", uploadMetadata.fullPath);
            console.log("Awaiting request-strip response")

            navigate("/discovered-companies")
        } catch (error) {
            console.error("Error during upload and process:", error);
        } finally {
            setUploadingFile(false);
        }
    };

    const fileData = () => {
        if (selectedFile) {
            return (
                <Box>
                    <Typography variant="subtitle1">File Details:</Typography>
                    <Typography variant="body2">File Name: {selectedFile.name}</Typography>
                    <Typography variant="body2">File Type: {selectedFile.type}</Typography>
                    <Typography variant="body2">Last Modified: {selectedFile.lastModifiedDate.toDateString()}</Typography>
                </Box>
            );
        }
    };


    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                minHeight: '100vh'
            }}
        >
            <Card
                sx={{
                    width: '512px',
                    padding: 4,
                    borderRadius: 8,
                    zIndex: 1,
                }}
            >
                <Stack spacing={2}>
                    <Typography variant="h2" fontSize={'2rem'}>Upload CVs</Typography>
                    <Typography variant='subtitle2'>Or <Link to={"/discovered-companies"}>View uploaded</Link></Typography>
                    <Box
                        component="form"
                        onSubmit={(event) => { event.preventDefault() }}
                        noValidate
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                        }}
                    >
                        <FormControl>
                            <FormLabel htmlFor="text">CSV file (containing LinkedIn URLs)</FormLabel>
                            <Typography variant="subtitle2">You must include the header 'linkedin_url' in one column of your .csv file.</Typography>
                            <input type="file" onChange={onFileChange} />
                        </FormControl>
                        {fileData()}
                        {
                            uploadingFile ?
                                <CenteredCircularProgress/> :

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    onClick={handleSubmit}
                                    disabled={selectedFile == null}
                                >
                                    Upload
                                </Button>
                        }
                    </Box>
                </Stack>
            </Card>
        </Box>
    )
}

export default UploadCV;