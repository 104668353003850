

import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Home = () => {

    return (
        <Stack
            spacing={2}
            sx={{
                // minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'space-between',
                alignItems: 'flex-start',
                paddingX: 16,
                paddingY: 8,
                // boxSizing: 'border-box',
            }}
        >
            <Typography variant='h2'>Home</Typography>
            {/* <Divider sx={{background: theme.palette.primary.main}}/> */}
            <Link to={"/discovered-companies"}><Typography variant='body1'>View uploaded</Typography></Link>
            <Link to={"/upload-cv"}><Typography variant='body1'>Upload new CVs/LinkedIn Urls</Typography></Link>
        </Stack>
    )
}

export default Home;