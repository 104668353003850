import { auth, storage } from './firebase';
import { ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

/**
 * uploads file to firebase storage
 * @param selectedFile the file to upload
 * @returns the files metadata. You can get the filename with metadata.fullPath.
 */
async function uploadFileToFirebase(selectedFile) {

    const { name } = selectedFile;
    const fileRef = ref(storage, `uploads/${auth.currentUser.uid}/${uuidv4()}/${name}`); // TODO: CRITICAL: On the server side, make sure that we can only upload to /upload/OUR USER ID, otherwise things get a bit fucky

    try {
        // Upload file to Firebase Storage
        const uploadSnapshot = await uploadBytes(fileRef, selectedFile);
        console.log("Uploaded file to Firebase Storage:", uploadSnapshot.metadata.fullPath);

        return uploadSnapshot.metadata
    } catch (error) {
        console.error("Error during upload and process:", error);
    }
}

export { uploadFileToFirebase }