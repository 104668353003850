const { FormControl, InputLabel, Select, MenuItem } = require("@mui/material");


const LanguageSelectDialog = ({ language, setLanguage }) => {


    const handleChange = (event) => {
        setLanguage(event.target.value);
    };

    const languageOptions = [
        ".Net", "Abap", "Actionscript", "Ada", "Apl", "Assembly", "Awk", "Bash", "C", "C#", "C++",
        "Clips", "Cobol", "Common Lisp", "Crystal", "Dart", "Elixir", "Erlang", "F#", "Fortran",
        "Go", "Groovy", "Haskell", "Java", "JavaScript", "Julia", "Kotlin", "Lua", "Matlab", "Nim",
        "Objective - C", "PHP", "Pascal", "Perl", "Pike", "Postscript", "Prolog", "Python", "Qml",
        "R", "Racket", "Ruby", "Rust", "SQL", "Scala", "Scheme", "Scratch", "Shell", "Smalltalk",
        "Solidity", "Swift", "Tcl", "TypeScript", "Vb", "Vbscript", "Vhdl", "Visual Basic", "Zig",
    ];

    return (
        <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
            <InputLabel id="demo-select-small-label">Language</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={language}
                label="Language"
                onChange={handleChange}
                defaultValue={"All"}
            >

                <MenuItem value={"All"}>All</MenuItem>
                {languageOptions.map((language, index) => {
                    return <MenuItem key={index} value={language}>{language}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}

export default LanguageSelectDialog;