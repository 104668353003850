import { Box, Collapse, IconButton, TableCell, TableContainer, Paper, TableRow, Typography, Table, TableHead, TableBody } from "@mui/material";
import { useState } from "react";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function Row({ row }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell>{row.industry}</TableCell>
                <TableCell>{row.staff_count}</TableCell>
                <TableCell><a href={row.linkedin_url}>LinkedIn</a></TableCell>
                <TableCell><a href={row.website_url}>Website</a></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Related Companies
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Staff Count</TableCell>
                                        <TableCell>LinkedIn Url</TableCell>
                                        <TableCell>Website Url</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.competitors.map((competitor) => (
                                        <TableRow key={competitor.name}>
                                            <TableCell>{competitor.name}</TableCell>
                                            <TableCell>{competitor.staff_count}</TableCell>
                                            <TableCell><a href={row.linkedin_url}>LinkedIn</a></TableCell>
                                            <TableCell><a href={row.website_url}>Website</a></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

const rows = [
    {
        "name": "Powertrial",
        "industry": "Medical Research Software",
        "staff_count": "45",
        "linkedin_url": "",
        "website_url": "",
        "competitors": [
            {
                "name": "CelluNova",
                "staff_count": "13",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "Powertrial",
                "staff_count": "24",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "HelixCore",
                "staff_count": "95",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "NeuroSphera",
                "staff_count": "18",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "EcoBiomatrix",
                "staff_count": "120",
                "linkedin_url": "",
                "website_url": ""
            }
        ]
    },
    {
        "name": "GeneActive",
        "industry": "Genetic Analysis Software",
        "staff_count": "60",
        "linkedin_url": "",
        "website_url": "",
        "competitors": [
            {
                "name": "GenomeWay",
                "staff_count": "50",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "SeqLogic",
                "staff_count": "40",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "DataHelix",
                "staff_count": "75",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "BioCodeX",
                "staff_count": "30",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "GenoXperts",
                "staff_count": "90",
                "linkedin_url": "",
                "website_url": ""
            }
        ]
    },
    {
        "name": "NeuroTrack",
        "industry": "Neuroimaging Solutions",
        "staff_count": "80",
        "linkedin_url": "",
        "website_url": "",
        "competitors": [
            {
                "name": "MindMapTech",
                "staff_count": "55",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "SynapseTech",
                "staff_count": "70",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "BrainScope",
                "staff_count": "95",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "CortexLab",
                "staff_count": "45",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "NeuroLogic",
                "staff_count": "100",
                "linkedin_url": "",
                "website_url": ""
            }
        ]
    },
    {
        "name": "BioMatrix AI",
        "industry": "AI for Biotechnology",
        "staff_count": "100",
        "linkedin_url": "",
        "website_url": "",
        "competitors": [
            {
                "name": "AI BioCore",
                "staff_count": "85",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "BioCompute",
                "staff_count": "110",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "GeneAI",
                "staff_count": "95",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "HelixAI",
                "staff_count": "70",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "NeuroGen AI",
                "staff_count": "125",
                "linkedin_url": "",
                "website_url": ""
            }
        ]
    },
    {
        "name": "EcoHealth Dynamics",
        "industry": "Health and Environmental Software",
        "staff_count": "120",
        "linkedin_url": "",
        "website_url": "",
        "competitors": [
            {
                "name": "GreenMedix",
                "staff_count": "90",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "EcoCare",
                "staff_count": "105",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "BioEcoHealth",
                "staff_count": "140",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "SustainaHealth",
                "staff_count": "115",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "EnviroMed",
                "staff_count": "135",
                "linkedin_url": "",
                "website_url": ""
            }
        ]
    },
    {
        "name": "HealthSphere",
        "industry": "Healthcare Analytics",
        "staff_count": "75",
        "linkedin_url": "",
        "website_url": "",
        "competitors": [
            {
                "name": "MediDataPro",
                "staff_count": "80",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "CareInsights",
                "staff_count": "65",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "HealthTrack",
                "staff_count": "95",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "WellnessMetrics",
                "staff_count": "60",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "DataVital",
                "staff_count": "100",
                "linkedin_url": "",
                "website_url": ""
            }
        ]
    },
    {
        "name": "ClinOptima",
        "industry": "Clinical Optimization Software",
        "staff_count": "50",
        "linkedin_url": "",
        "website_url": "",
        "competitors": [
            {
                "name": "OptiMedix",
                "staff_count": "40",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "ClinAnalytics",
                "staff_count": "55",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "MedOptimize",
                "staff_count": "70",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "HealthOptics",
                "staff_count": "45",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "CarePerfect",
                "staff_count": "65",
                "linkedin_url": "",
                "website_url": ""
            }
        ]
    },
    {
        "name": "PrecisionMed",
        "industry": "Personalized Medicine Software",
        "staff_count": "85",
        "linkedin_url": "",
        "website_url": "",
        "competitors": [
            {
                "name": "TailorMeds",
                "staff_count": "70",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "MedGenomics",
                "staff_count": "95",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "ExactCare",
                "staff_count": "110",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "GenoCare",
                "staff_count": "80",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "BioPrecision",
                "staff_count": "120",
                "linkedin_url": "",
                "website_url": ""
            }
        ]
    },
    {
        "name": "PharmaSys",
        "industry": "Pharmaceutical Systems Software",
        "staff_count": "65",
        "linkedin_url": "",
        "website_url": "",
        "competitors": [
            {
                "name": "PharmaTech",
                "staff_count": "50",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "MediSystems",
                "staff_count": "85",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "RxSolutions",
                "staff_count": "95",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "DrugLogic",
                "staff_count": "75",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "HealthRx",
                "staff_count": "105",
                "linkedin_url": "",
                "website_url": ""
            }
        ]
    },
    {
        "name": "TheraTrack",
        "industry": "Therapeutics Tracking Software",
        "staff_count": "90",
        "linkedin_url": "",
        "website_url": "",
        "competitors": [
            {
                "name": "TrackMed",
                "staff_count": "85",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "TherapyLog",
                "staff_count": "75",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "CuraTrack",
                "staff_count": "95",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "HealScope",
                "staff_count": "100",
                "linkedin_url": "",
                "website_url": ""
            },
            {
                "name": "PathCare",
                "staff_count": "120",
                "linkedin_url": "",
                "website_url": ""
            }
        ]
    }
];

const CompaniesTable = () => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Name</TableCell>
                        <TableCell>Industry</TableCell>
                        <TableCell>Staff count</TableCell>
                        <TableCell>LinkedIn Url</TableCell>
                        <TableCell>Website Url</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <Row key={row.name} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CompaniesTable