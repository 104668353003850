import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, LinearProgress, IconButton, useTheme, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import { collection, query, where, orderBy, getDocs, Timestamp } from "firebase/firestore";
import { db } from "remote/firebase"

import UpdateIcon from '@mui/icons-material/Update';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CenteredCircularProgress from "components/CenteredCircularProgress";

const FileProcessingStatusBox = ({onComplete}) => {

    const { currentUser } = useAuth();
    const theme = useTheme();

    const [fileProcessingMeta, setFileProcessingMeta] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    function filesAreProcessing(files) {
        if (files.length === 0)
            return false;

        return files.some(file => file.status === 'starting' || file.status === 'processing');
    }

    useEffect(() => {
        async function getProcessingFiles() {
            try {
                const oneWeekAgo = Timestamp.fromDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)); // Calculate date 1 week ago

                console.log(currentUser.uid)
                // Create a query
                const filesQuery = query(
                    collection(db, "processing_files"),
                    where("owner", "==", currentUser.uid),
                    where("started", ">=", oneWeekAgo),
                    orderBy("started", "desc")  // Order by the 'updated' field, descending
                );

                // Fetch documents
                const querySnapshot = await getDocs(filesQuery);

                // Map the documents to an array of data
                const files = querySnapshot.docs.map((doc) => ({
                    id: doc.id, // Include document ID
                    ...doc.data(), // Include document data
                }));

                return files;
            } catch (error) {
                console.error("Error fetching processing files:", error);
                setErrorMessage(`Error fetching processing files: ${error}`);
                return [];
            }
        }

        async function getFileProcessingStateUntilComplete() {
            const delay = ms => new Promise(res => setTimeout(res, ms));

            let attempts = 0; // Track polling attempts
            let files = [];

            while (attempts < 5 || filesAreProcessing(files)) {

                // Fetch processing files
                files = await getProcessingFiles();
                setFileProcessingMeta(files);
                setLoading(false);
                console.log(files);

                // Check if files are still processing
                if (attempts >= 5 && !filesAreProcessing(files)) {
                    console.log("All files processed (and probably no more to come)!");
                    onComplete();
                    break;
                }

                // Increment attempts and delay for 3 seconds
                attempts++;
                console.log(`Poll no. ${attempts} of 5: Files still processing. Retrying in 2 seconds...`);
                await delay(2000);
            }

            setLoading(false);
        }

        setLoading(true);
        getFileProcessingStateUntilComplete();

    }, [currentUser]); // Dependency array includes required props


    function convertToRelativeTime(fileTimeStamp) {
        if (!fileTimeStamp) return "";
    
        const now = new Date();
        const completedAt = new Date(fileTimeStamp.seconds * 1000);
    
        const msPerDay = 24 * 60 * 60 * 1000;
    
        const elapsedDays = Math.floor((now - completedAt) / msPerDay);
    
        if (elapsedDays === 0) {
            // Same day
            return `Today (${completedAt.getHours().toString().padStart(2, '0')}:${completedAt.getMinutes().toString().padStart(2, '0')})`;
        } else if (elapsedDays === 1) {
            // One day ago
            return "Yesterday";
        } else if (elapsedDays > 1 && elapsedDays <= 4) {
            // 2-4 days ago
            return `${elapsedDays} days ago`;
        } else {
            // Beyond 4 days, return a formatted date
            return completedAt.toLocaleDateString(); // Adjust locale as needed
        }
    }    

    return (

        <Box
            sx={{
                border: "1px solid black",
                borderRadius: "8px",
                padding: "16px",
                maxWidth: "1500px",
                width: "100%",
                margin: "0 0 0 auto",
                boxSizing: 'border-box'
            }}
        >
            {loading ?
                <CenteredCircularProgress /> :
                errorMessage ?
                    <Typography variant="body2">{errorMessage}</Typography> :
                    <TableContainer sx={{maxHeight: '300px', overflowY: 'scroll'}}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h5">
                                            File
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h5">
                                            Progress
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h5" sx={{ minWidth: '120px' }}>
                                            Last Update
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h5">
                                            Notes
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h5">
                                            Status
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fileProcessingMeta.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Typography variant='body2'>{row.original_file_name && row.original_file_name}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <LinearProgress
                                                variant="determinate"
                                                value={(row.rows_processed / row.row_count) * 100}
                                                sx={{
                                                    height: "10px",
                                                    borderRadius: "5px",
                                                    backgroundColor: theme.palette.grey,
                                                    "& .MuiLinearProgress-bar": {
                                                        backgroundColor: theme.palette.primary.main,
                                                    },
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant='body2'>{row.complete ? convertToRelativeTime(row.complete) : convertToRelativeTime(row.started)}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant='body2'>{row.failure_reason && row.failure_reason}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex" alignItems="center" gap="8px">
                                                <Tooltip title={row.status[0].toUpperCase() + row.status.slice(1)}>
                                                    <IconButton>
                                                        {row.status === "starting" && <UpdateIcon style={{ color: "grey" }} />}
                                                        {row.status === "processing" && <PlayCircleFilledIcon style={{ color: theme.palette.secondary.main }} />}
                                                        {row.status === "complete" && <CheckCircleIcon style={{ color: "green" }} />}
                                                        {row.status === "failed" && <ErrorIcon style={{ color: "red" }} />}
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </Box >
    );
};

export default FileProcessingStatusBox;

// const data = [
//     {
//         status: "processing",
//         progress: 50,
//         note: "",
//         icon: <AccessTimeIcon style={{ color: "blue" }} />,
//     },
//     {
//         status: "failed",
//         progress: 10,
//         note: "something shat the bed",
//         icon: <ErrorIcon style={{ color: "red" }} />,
//     },
//     {
//         status: "complete",
//         progress: 100,
//         note: "",
//         icon: <CheckCircleIcon style={{ color: "green" }} />,
//     },
// ];

// const FileProcessingStatusBox = () => {
//     return (
// <Box
//     sx={{
//         border: "1px solid black",
//         borderRadius: "8px",
//         padding: "16px",
//         maxWidth: "800px",
//         margin: "0 auto",
//     }}
// >
//     <TableContainer>
//         <Table>
//             <TableHead>
//                 <TableRow>
//                     <TableCell>
//                         <Typography variant="subtitle1" fontWeight="bold">
//                             Status
//                         </Typography>
//                     </TableCell>
//                     <TableCell>
//                         <Typography variant="subtitle1" fontWeight="bold">
//                             Progress
//                         </Typography>
//                     </TableCell>
//                     <TableCell>
//                         <Typography variant="subtitle1" fontWeight="bold">
//                             Notes
//                         </Typography>
//                     </TableCell>
//                 </TableRow>
//             </TableHead>
//             <TableBody>
//                 {data.map((row, index) => (
//                     <TableRow key={index}>
//                         <TableCell>
//                             <Box display="flex" alignItems="center" gap="8px">
//                                 <IconButton>{row.icon}</IconButton>
//                                 <Typography>{row.status}</Typography>
//                             </Box>
//                         </TableCell>
//                         <TableCell>
//                             <LinearProgress
//                                 variant="determinate"
//                                 value={row.progress}
//                                 sx={{
//                                     height: "10px",
//                                     borderRadius: "5px",
//                                     backgroundColor: "black",
//                                     "& .MuiLinearProgress-bar": {
//                                         backgroundColor: "pink",
//                                     },
//                                 }}
//                             />
//                         </TableCell>
//                         <TableCell>
//                             <Typography>{row.note}</Typography>
//                         </TableCell>
//                     </TableRow>
//                 ))}
//             </TableBody>
//         </Table>
//     </TableContainer>
// </Box>
//     );
// };

// export default FileProcessingStatusBox;
