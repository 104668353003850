import { Box, Card, FormControl, FormLabel, Stack, TextField, Typography, Button, DialogContent, DialogContentText, DialogActions, DialogTitle, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import HazyBackground from "components/HazyBackground";
import { httpsCallableFromURL } from 'firebase/functions';
import { functions } from "remote/firebase";

const LicenseKeySubmit = () => {
    const [keyError, setKeyError] = useState(false);
    const [keyErrorMessage, setKeyErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // Check if the logout reason is "device-conflict"
        if (searchParams.get("lr") === "expired") {
            setDialogOpen(true);
        }
    }, [searchParams]);


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (isSubmitting) return;

        setIsSubmitting(true);

        const data = new FormData(event.currentTarget);
        const key = data.get('key');

        // Validate the key input
        if (!key) {
            setKeyError(true);
            setKeyErrorMessage('You must submit a value for the key');
            setIsSubmitting(false);
            return;
        }

        setKeyError(false);
        setKeyErrorMessage('');

        try {
            // Call the Firebase function
            const attachLicenseKey = httpsCallableFromURL(functions, 'https://europe-west1-two-degrees-search.cloudfunctions.net/attachLicenseKey');
            const response = await attachLicenseKey({ key });

            console.log('Response from Firebase:', response.data);

            // Handle successful response
            console.log(`Success: ${response.data.message}`);
            navigate('/');
        } catch (error) {
            // Handle errors from Firebase
            console.error('Error attaching license key:', error);
            setKeyError(true);

            console.log(error.code);

            // Provide a user-friendly error message
            if (error.code === 'functions/unauthenticated') {
                setKeyErrorMessage('You must be authenticated to submit a license key.');
            } else if (error.code === 'functions/invalid-argument') {
                setKeyErrorMessage('Invalid license key provided.');
            } else if (error.code === 'functions/not-found') {
                setKeyErrorMessage('License key does not exist.');
            } else if (error.code === 'functions/already-exists') {
                setKeyErrorMessage('License key is already attached to another account.');
            } else if (error.code === 'functions/failed-precondition') {
                setKeyErrorMessage('License key has expired.');
            } else {
                setKeyErrorMessage('An error occurred. Please try againn.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <HazyBackground>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>License Exired</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You have been logged out because your license key has expired.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Card
                sx={{
                    width: '512px',
                    padding: 4,
                    borderRadius: 8,
                    zIndex: 1,
                }}
            >
                <Stack spacing={2}>
                    <Typography variant="h3">Submit License Key</Typography>

                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                        }}
                    >
                        <FormControl>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormLabel htmlFor="key">License Key</FormLabel>
                            </Box>
                            <TextField
                                error={keyError}
                                helperText={keyErrorMessage}
                                name="key"
                                placeholder="••••••"
                                type="password"
                                id="key"
                                autoComplete="license-key"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={keyError ? 'error' : 'primary'}
                            />
                        </FormControl>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </Button>
                    </Box>
                </Stack>
            </Card>
        </HazyBackground>
    );
};

export default LicenseKeySubmit;
