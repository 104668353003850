import { Outlet, BrowserRouter, Routes, Route } from "react-router-dom";
import PasswordProtectedRoute from "components/PasswordProtectedRoute";
import LicenseProtectedRoute from "components/LicenseProtectedRoute";
import NavBar from "components/NavBar";

import SignUp from "pages/SignUp/SignUp";
import SignIn from "pages/SignIn/SignIn";
import LicenseKeySubmit from "pages/LicenseKey/LicenseKey"
import Home from "pages/Home/Home";
import UploadCV from "pages/UploadCVs/UploadCV";
import DiscoveredCompanies from "pages/DiscoveredCompanies/DiscoveredCompanies";

import NoPage from "pages/ErrorResponse/NoPage";

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={
					<>
						<NavBar />
						<Outlet />
					</>
				}>
					{/* No auth required */}
					<Route path="sign-up" element={<SignUp />} />
					<Route path="sign-in" element={<SignIn />} />

					{/* Protected Routes */}
					<Route path="/" element={
						<PasswordProtectedRoute>
							<Outlet />
						</PasswordProtectedRoute>
					}>

						<Route path="activate-license" element={<LicenseKeySubmit />} />

						<Route path="/" element={
							<LicenseProtectedRoute>
								<Outlet />
							</LicenseProtectedRoute>
						}>

							<Route index element={<Home />} />

							<Route path="discovered-companies" element={<DiscoveredCompanies />} />
							<Route path="upload-cv" element={<UploadCV />} />
						</Route>
					</Route>

					<Route path="*" element={<NoPage />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}