import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
    // sendPasswordResetEmail,
    // sendEmailVerification
} from "firebase/auth"

import { auth } from  "./firebase"

export const doCreateUserWithEmailAndPassword = async (email, password) => {
    return await createUserWithEmailAndPassword(auth, email, password)
        .catch((error) => {throw error;});
}

export const doSignInWithEmailAndPassword = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
        .catch((error) => {throw error;});
}

export const doSignInWithGoogle = async() => {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider)
        .catch((error) => {throw error;});
    
    // save to firestore
    // result.user

    return result;
}

export async function doSignOut() {
    return await auth.signOut()
}

// export const doPasswordReset = (email) => {
//     return sendPasswordResetEmail(auth, email);
// }

// export const doPasswordChange = (password) => {
//     return updatePassword(auth.currentUser, password);
// }

// export const doSendEmailVerification = () => {
//     return sendEmailVerification(auth.currentUser, {
//         url: `${window.location.origin}/`,
//     });
// }

export const validateAccountInitialised = async () => {

    return true;

    // const idToken = await getFirebaseIdToken()
    
    // try {
    //     // const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${auth.currentUser.uid}/`, {
    //     const response = await fetch(`${REACT_APP_API_URL}/user/${auth.currentUser.uid}/`, {
    //         method: 'GET',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Authorization': `Bearer ${idToken}`,
    //         }
    //     });

    //     if (!response.ok) {
    //         throw new Error(`Error fetching data: ${response.statusText}`);
    //     }

    //     const data = await response.json();

    //     // Ensure that the value exists and is a boolean
    //     return !!data["isInitialisedAsUser"];
    // } catch (error) {
    //     console.error('Error:', error);
    //     return null;  // Return false in case of an error
    // }
};

export const getFirebaseIdToken = async () => {
    try {
        // Wait for the ID token to be retrieved
        const idToken = await auth.currentUser.getIdToken();
        return idToken; // Return the token once it's retrieved
    } catch (error) {
        console.error(error);
        return null; // Return null in case of an error
    }
};
