import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import CenteredCircularProgress from './CenteredCircularProgress';

const PasswordProtectedRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !currentUser) navigate("/sign-in");
  }, [currentUser, loading, navigate])

  return (loading ? <CenteredCircularProgress /> : children);
};

export default PasswordProtectedRoute;
