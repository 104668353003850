import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import CenteredCircularProgress from './CenteredCircularProgress';
import { query, where, collection, getDocs, limit, doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from "remote/firebase";
import { v4 as uuidv4 } from 'uuid';
import { doSignOut } from 'remote/auth';

const LicenseProtectedRoute = ({ children }) => {
	const navigate = useNavigate();
	const { currentUser } = useAuth();
	const [license, setLicense] = useState("");
	const [loading, setLoading] = useState(true);
	const [deviceId] = useState(() => {
		const savedDeviceId = localStorage.getItem("deviceId") || uuidv4();
		localStorage.setItem("deviceId", savedDeviceId);
		return savedDeviceId;
	});

	useEffect(() => {
		let unsubscribe = null; // Store the unsubscribe function for the Firestore listener

		// Function to get the license and listen for device changes
		async function getLicense() {
			if (!currentUser || !currentUser.uid) return;

			try {
				// Query to get licenses for the current user
				const licenseQuery = query(
					collection(db, "license_keys"),
					where("obtained_by", "==", currentUser.uid),
					limit(1)
				);

				// Execute the query
				const licenseSnapshot = await getDocs(licenseQuery);

				// Check if a license exists
				if (!licenseSnapshot.empty) {
					// Get the first document
					const licenseDoc = licenseSnapshot.docs[0];
					const license = { id: licenseDoc.id, ...licenseDoc.data() };

					if (license.expiry_date) {
						const currentTime = new Date(); // Get the current time
						const expiryDate = license.expiry_date.toDate(); // Convert Firestore Timestamp to JavaScript Date
					
						// Check if the license is expired
						if (expiryDate < currentTime) {
							console.log("License key has expired.");
							navigate("/activate-license?lr=expired"); // Redirect to sign-in with the expired reason
							return; // Exit the function to prevent further processing
						}
					}
					console.log(license);
					setLicense(license.id); // Set the license ID

					// Update or verify the current_device_id in Firestore
					const userDocRef = doc(db, "users", currentUser.uid);
					await updateDoc(userDocRef, { current_device_id: deviceId });

					// Set up a listener for device changes
					unsubscribe = listenForDeviceChanges(currentUser.uid);
				} else {
					console.log("No license found.");
				}
			} catch (e) {
				console.log(e);
			} finally {
				setLoading(false);
			}
		}

		// Listen for device changes
		function listenForDeviceChanges(userId) {
			const userDocRef = doc(db, "users", userId);

			// Listen for real-time updates to the current_device_id field
			return onSnapshot(userDocRef, (docSnapshot) => {
				if (docSnapshot.exists()) {
					const userData = docSnapshot.data();

					// If the current_device_id changes, log out
					if (userData.current_device_id && userData.current_device_id !== deviceId) {
						console.log("Logged out due to login on another device.");
						cleanupListener(); // Detach listener before logging out
						doSignOut().then(() => {
							navigate("/sign-in?lr=device-conflict");
						});
					}
				}
			});
		}

		setLoading(true);
		getLicense();

		// Cleanup listener when component unmounts or when currentUser changes
		return () => {
			cleanupListener();
		};

		// Cleanup function to unsubscribe from Firestore updates
		function cleanupListener() {
			if (unsubscribe) {
				unsubscribe(); // Unsubscribe from Firestore updates
				unsubscribe = null; // Reset the reference
			}
		}
	}, [currentUser, deviceId, navigate]);

	useEffect(() => {
		if (!loading && license === "") {
			navigate("/activate-license");
		}
	}, [license, loading, navigate]);

	return loading ? <CenteredCircularProgress /> : children;
};

export default LicenseProtectedRoute;
