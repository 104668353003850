import { Box, Card, FormControl, FormLabel, Stack, TextField, Typography, Link, Button, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { doCreateUserWithEmailAndPassword, doSignInWithGoogle } from "remote/auth";
import googleLogo from 'assets/Login/GoogleLogo.png'
import HazyBackground from "components/HazyBackground";
import { updateUserDocument } from 'remote/firestore';

const SignUp = () => {
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorMessage, setNameErrorMessage] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);

    const navigate = useNavigate();

    const { userLoggedIn } = useAuth();
    useEffect(() => {
        if (userLoggedIn) {
            navigate("/");
        }
    }, [navigate, userLoggedIn])

    const validateInputs = () => {
        const email = document.getElementById('email');
        const password = document.getElementById('password');
        const name = document.getElementById('name');

        let isValid = true;

        if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
            setEmailError(true);
            setEmailErrorMessage('Please enter a valid email address.');
            isValid = false;
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
        }

        if (!password.value || password.value.length < 6) {
            setPasswordError(true);
            setPasswordErrorMessage('Password must be at least 6 characters long.');
            isValid = false;
        } else {
            setPasswordError(false);
            setPasswordErrorMessage('');
        }

        if (!name.value || name.value.length < 1) {
            setNameError(true);
            setNameErrorMessage('Name is required.');
            isValid = false;
        } else {
            setNameError(false);
            setNameErrorMessage('');
        }

        return isValid;
    };

    //   import { db } from './firebase'; // import Firebase setup
    // import { collection, addDoc } from 'firebase/firestore'; // import Firestore methods

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (nameError || emailError || passwordError) {
            return;
        }

        const data = new FormData(event.currentTarget);
        const name = data.get('name');
        const email = data.get('email');
        const password = data.get('password');

        if (isSigningIn) return;

        setIsSigningIn(true);

        try {
            const userCredential = await doCreateUserWithEmailAndPassword(email, password);

            const delay = ms => new Promise(res => setTimeout(res, ms));

            for (let attempt = 0; attempt < 5; attempt++) {
                console.log(`Submitting display name (Attempt ${attempt + 1} of 5 )`)
                try {
                    await updateUserDocument({
                        displayName: name
                    })
                    break;
                }
                catch (error) {
                    await delay(1000);
                }
            }

            console.log(userCredential);

            navigate('/'); // Navigate after saving
        } catch (error) {
            console.log(error);
            setPasswordErrorMessage(String(error));
        } finally {
            setIsSigningIn(false);
        }
    };

    const onGoogleSignIn = (e) => {
        e.preventDefault()
        if (isSigningIn)
            return;

        setIsSigningIn(true)
        doSignInWithGoogle()
            .then((userCredential) => {
                console.log(userCredential);
                navigate('/');
            })
            .catch((error) => {
                console.log(error);
                setPasswordErrorMessage(String(error));
            })
            .finally(() => {
                setIsSigningIn(false);
            });
    }

    return (
        <HazyBackground>

            <Card
                sx={{
                    maxWidth: '512px',
                    width: '80%',
                    padding: 4,
                    borderRadius: 8,
                    zIndex: 1,
                }}
            >
                <Stack spacing={2}>
                    <Typography variant="h3">Sign up</Typography>

                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                    >
                        <FormControl>
                            <FormLabel htmlFor="name">Full name</FormLabel>
                            <TextField
                                autoComplete="name"
                                name="name"
                                required
                                fullWidth
                                id="name"
                                placeholder="Jon Snow"
                                error={nameError}
                                helperText={nameErrorMessage}
                                color={nameError ? 'error' : 'primary'}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                placeholder="your@email.com"
                                name="email"
                                autoComplete="email"
                                variant="outlined"
                                error={emailError}
                                helperText={emailErrorMessage}
                                color={passwordError ? 'error' : 'primary'}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="password">Password</FormLabel>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                placeholder="••••••"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                variant="outlined"
                                error={passwordError}
                                helperText={passwordErrorMessage}
                                color={passwordError ? 'error' : 'primary'}
                            />
                        </FormControl>
                        {/* <FormControlLabel
                            control={<Checkbox defaultChecked={true} value="allowExtraEmails" color="primary" />}
                            label="I want to receive updates via email."
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={validateInputs}
                        >
                            Sign up
                        </Button>
                        <Typography sx={{ textAlign: 'center' }}>
                            Already have an account?{' '}
                            <span>
                                <Link
                                    href="/sign-in/"
                                    variant="body2"
                                    sx={{ alignSelf: 'center' }}
                                >
                                    Sign in
                                </Link>
                            </span>
                        </Typography>
                    </Box>
                    <Divider><Typography variant="body2">or</Typography></Divider>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={onGoogleSignIn}
                            startIcon={<img src={googleLogo} alt="google logo" height={32} />}
                        >
                            <Typography variant="body2">Sign up with Google</Typography>
                        </Button>
                    </Box>
                </Stack>
            </Card>

        </HazyBackground >
    )
}

export default SignUp;