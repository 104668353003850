import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from './firebase';


async function getDocument(path, ...pathSegments) {
    try {
        // Get the currently authenticated user
        const user = auth.currentUser;

        if (!user) {
            throw new Error("User is not authenticated");
        }

        const docRef = doc(db, "path", pathSegments);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            const data = docSnapshot.data()
            console.log(`Firebase data at path ${path}:`, data);
            return data;
        } else {
            return null;
        }
    } catch (error) {
        console.log("Error getting document:", error);
        throw error;
    }
}

async function updateUserDocument(updatedData) {
    try {
        // Get the currently authenticated user
        const user = auth.currentUser;

        if (!user) {
            throw new Error("User is not authenticated");
        }

        // Reference to the user document
        const userDocRef = doc(db, 'users', user.uid);

        // Update the user document
        await updateDoc(userDocRef, updatedData);

        console.log("User document updated successfully");
    } catch (error) {
        console.error("Error updating user document:", error);
        throw error;
    }
};

async function getUserDocument() {
    try {
        const user = auth.currentUser;
        const userDoc = await getDocument("users", user.uid);

        if (userDoc) {
            return userDoc;
        } else {
            throw new Error("Somehow the user document does not exist. UID: ", user.uid);
        }
    } catch (error) {
        throw error;
    }
}

export { updateUserDocument, getUserDocument };